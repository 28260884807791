<template>
  <div
    class="pa-2"
    style="white-space: pre-line;"
  >
    <h1 class="headline mb-6 font-weight-bold">
      利用規約
    </h1>
    <p class="mb-12">
      この利用規約（以下，「本規約」といいます。）は，「公認会計士試験過去問コム」（以下，「当サイト」といいます。）がこのウェブサイト上で提供するサービス（以下，「本サービス」といいます。）の利用条件を定めるものです。登録ユーザーの皆さま（以下，「ユーザー」といいます。）には，本規約に従って，本サービスをご利用いただきます。
    </p>
    <h2 class="title mb-2 font-weight-black">
      第1条（適用）
    </h2>
    <p class="body-1 mb-1">
      本規約は，ユーザーと当サイトとの間の本サービスの利用に関わる一切の関係に適用されるものとします。<br>当サイトは本サービスに関し，本規約のほか，ご利用にあたってのルール等，各種の定め（以下，「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず，本規約の一部を構成するものとします。<br>本規約の規定が前条の個別規定の規定と矛盾する場合には，個別規定において特段の定めなき限り，個別規定の規定が優先されるものとします。
    </p>
    <v-divider class="ma-6" />
    <h2 class="title mb-2 font-weight-black">
      第2条（利用登録）
    </h2>
    <p class="body-1 mb-1">
      本サービスにおいては，登録希望者が本規約に同意の上，当サイトの定める方法によって利用登録を申請し，当サイトがこれを承認することによって，利用登録が完了するものとします。<br>当サイトは，利用登録の申請者に以下の事由があると判断した場合，利用登録の申請を承認しないことがあり，その理由については一切の開示義務を負わないものとします。
    </p>
    <ul>
      <li>利用登録の申請に際して虚偽の事項を届け出た場合</li>
      <li>本規約に違反したことがある者からの申請である場合</li>
      <li>その他，当サイトが利用登録を相当でないと判断した場合</li>
    </ul>
    <v-divider class="ma-6" />
    <h2 class="title mb-2 font-weight-black">
      第3条（アカウント利用サービス）
    </h2>
    <p class="body-1 mb-1">
      本サービスでは、正誤記録、ブックマーク等のサービスを含む一部機能の利用において、ユーザーアカウントの登録・取得が必要となります。<br>ユーザーは、本サービス上で当サイト所定のソーシャルログインに関する手続を行うことにより、ユーザーアカウントを登録・取得します。ソーシャルログインとは、ソーシャルログイン対象SNSのアカウントを利用して本アプリにログインするために、本アプリとソーシャルログイン対象SNSのアカウントを連携させることをいいます。<br>ユーザアカウントの取得には、ソーシャルログイン対象SNSにおけるアカウントが必要です。
    </p>
    <v-divider class="ma-6" />
    <h2 class="title mb-2 font-weight-black">
      第4条（アカウント・パスワードの管理）
    </h2>
    <p class="body-1 mb-1">
      ユーザーは、ソーシャルログイン対象SNSのアカウント及び当該アカウントに対応するパスワード（以下、あわせて「アカウント等」といいます。）を自ら責任をもって管理するものとし、アカウント等を使用してなされた一切の行為及びその結果について、その行為をユーザーが行ったか否かを問わず一切の責任を負うものとします。<br>ユーザーは、自己のアカウント等を第三者に貸与し、又は使用させることはできないものとします。万が一、ユーザーのアカウント等が第三者に使用されたことによりユーザー又はその他の者が損害を被った場合であっても、その原因を問わず、当社は一切の責任を負わないものとします。<br>ユーザーは、他のユーザーのアカウント等を使用して本サービスを利用することはできないものとします。万が一、ユーザーが他のユーザーのアカウント等を使用して本サービスを利用した場合、ユーザーはそれによって生じた一切の損害を直ちに賠償するほか、発生した一切の紛争をその責任と負担において解決するものとします。
    </p>
    <v-divider class="ma-6" />
    <h2 class="title mb-2 font-weight-black">
      第5条（知的財産権）
    </h2>
    <p class="body-1 mb-1">
      本サービスによって提供される商品写真その他のコンテンツ（以下「コンテンツ」といいます）の著作権又はその他の知的所有権は,当サイト及びコンテンツ提供者などの正当な権利者に帰属し,ユーザーは,これらを無断で複製,転載,改変,その他の二次利用をすることはできません。
    </p>
    <v-divider class="ma-6" />
    <h2 class="title mb-2 font-weight-black">
      第6条（禁止事項）
    </h2>
    <p class="body-1 mb-1">
      ユーザーは，本サービスの利用にあたり，以下の行為をしてはなりません。
    </p>
    <ul>
      <li>法令または公序良俗に違反する行為</li>
      <li>犯罪行為に関連する行為</li>
      <li>本サービスの内容等，本サービスに含まれる著作権，商標権ほか知的財産権を侵害する行為</li>
      <li>当サイト，ほかのユーザー，またはその他第三者のサーバーまたはネットワークの機能を破壊したり，妨害したりする行為</li>
      <li>本サービスによって得られた情報を商業的に利用する行為</li>
      <li>当サイトのサービスの運営を妨害するおそれのある行為</li>
      <li>不正アクセスをし，またはこれを試みる行為</li>
      <li>他のユーザーに関する個人情報等を収集または蓄積する行為</li>
      <li>不正な目的を持って本サービスを利用する行為</li>
      <li>本サービスの他のユーザーまたはその他の第三者に不利益，損害，不快感を与える行為</li>
      <li>他のユーザーに成りすます行為</li>
      <li>当サイトが許諾しない本サービス上での宣伝，広告，勧誘，または営業行為</li>
      <li>当サイトのサービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為</li>
      <li>その他，当サイトが不適切と判断する行為</li>
    </ul>
    <v-divider class="ma-6" />
    <h2 class="title mb-2 font-weight-black">
      第7条（本サービスの提供の停止等）
    </h2>
    <p class="body-1 mb-1">
      当サイトは，以下のいずれかの事由があると判断した場合，ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
    </p>
    <ul>
      <li>本サービスにかかるコンピュータシステムの保守点検または更新を行う場合</li>
      <li>地震，落雷，火災，停電または天災などの不可抗力により，本サービスの提供が困難となった場合</li>
      <li>コンピュータまたは通信回線等が事故により停止した場合</li>
      <li>その他，当サイトが本サービスの提供が困難と判断した場合</li>
    </ul>
    <p class="body-1 mb-1">
      当サイトは，本サービスの提供の停止または中断により，ユーザーまたは第三者が被ったいかなる不利益または損害についても，一切の責任を負わないものとします。
    </p>
    <v-divider class="ma-6" />
    <h2 class="title mb-2 font-weight-black">
      第8条（利用制限および登録抹消）
    </h2>
    <p class="body-1 mb-1">
      当サイトは，ユーザーが以下のいずれかに該当する場合には，事前の通知なく，ユーザーとしての登録を抹消することができるものとします。
    </p>
    <ul>
      <li>本規約のいずれかの条項に違反した場合</li>
      <li>登録事項に虚偽の事実があることが判明した場合</li>
      <li>料金等の支払債務の不履行があった場合</li>
      <li>当サイトからの連絡に対し，一定期間返答がない場合</li>
      <li>本サービスについて，最終の利用から一定期間利用がない場合</li>
      <li>その他，当サイトが本サービスの利用を適当でないと判断した場合</li>
    </ul>
    <p class="body-1 mb-1">
      当サイトは，本条に基づき当サイトが行った行為によりユーザーに生じた損害について，一切の責任を負いません。
    </p>
    <v-divider class="ma-6" />
    <h2 class="title mb-2 font-weight-black">
      第9条（退会）
    </h2>
    <p class="body-1 mb-1">
      ユーザーは，当サイトの定める退会手続により，本サービスから退会できるものとします。
    </p>
    <v-divider class="ma-6" />
    <h2 class="title mb-2 font-weight-black">
      第10条（保証の否認および免責事項）
    </h2>
    <p class="body-1 mb-1">
      当サイトは，本サービスに事実上または法律上の瑕疵（安全性，信頼性，正確性，完全性，有効性，特定の目的への適合性，セキュリティなどに関する欠陥，エラーやバグ，権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。
    </p><br>当サイトは，本サービスに起因してユーザーに生じたあらゆる損害について一切の責任を負いません。ただし，本サービスに関する当サイトとユーザーとの間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合，この免責規定は適用されません。
    <v-divider class="ma-6" />
    <h2 class="title mb-2 font-weight-black">
      第11条（サービス内容の変更等）
    </h2>
    <p class="body-1 mb-1">
      当サイトは，ユーザーに通知することなく，本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし，これによってユーザーに生じた損害について一切の責任を負いません。
    </p>
    <v-divider class="ma-6" />
    <h2 class="title mb-2 font-weight-black">
      第12条（利用規約の変更）
    </h2>
    <p class="body-1 mb-1">
      当サイトは，必要と判断した場合には，ユーザーに通知することなくいつでも本規約を変更することができるものとします。なお，本規約の変更後，本サービスの利用を開始した場合には，当該ユーザーは変更後の規約に同意したものとみなします。
    </p>
    <v-divider class="ma-6" />
    <h2 class="title mb-2 font-weight-black">
      第13条（個人情報の取扱い）
    </h2>
    <p class="body-1 mb-1">
      当サイトは，本サービスの利用によって取得する個人情報については，当サイト「プライバシーポリシー」に従い適切に取り扱うものとします。
    </p>
    <v-divider class="ma-6" />
    <h2 class="title mb-2 font-weight-black">
      第14条（通知または連絡）
    </h2>
    <p class="body-1 mb-1">
      ユーザーと当サイトとの間の通知または連絡は，当サイトの定める方法によって行うものとします。
    </p>
    <v-divider class="ma-6" />
    <h2 class="title mb-2 font-weight-black">
      第15条（過去問題の使用）
    </h2>
    <p class="body-1 mb-1">
      当サイトに掲載されている公認会計士試験短答式試験の各問題文は、公認会計士・監査審査会の「当ウェブサイトのコンテンツの利用について」に基づき利用しています。<br>その一部については問題文の性質や法令、基準の改正を理由として、改題を行なっている点にご留意ください。
    </p>
    <p>
      出典：<a
        class="blue--text"
        href="https://www.fsa.go.jp/cpaaob/kouninkaikeishi-shiken/index.html"
      >公認会計士・監査審査会ウェブサイト</a>
    </p>
    <v-divider class="ma-6" />
    <h2 class="title mb-2 font-weight-black">
      第16条（準拠法・裁判管轄）
    </h2>
    <p class="body-1 mb-1">
      本規約の解釈にあたっては，日本法を準拠法とします。<br>本アプリの利用に関する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
    </p>
  </div>
</template>


<script>
  export default {
    name: "Terms"
  }
</script>

<style scoped>
  .statement {
    white-space: pre-line
  }

</style>
